import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/JOojQ1p10s4&">
    <SEO title="Determined Not Defiant - Living in Babylon" />
  </Layout>
)
export default SermonPost
